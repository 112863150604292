import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children }: { children: ReactNode }) => {
  const containerRef = useRef<HTMLElement>(document.createElement('div'));

  useEffect(() => {
    const container = containerRef.current;

    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, containerRef?.current);
};
