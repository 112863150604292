import { ReactNode } from 'react';

import { CartProgressIndicator } from '@/core/ui/components/CartProgressIndicator/CartProgressIndicator';
import { Portal } from '@/core/ui/components/Portal/Portal';
import { CartSidebar } from '@/modules/cart/components/CartSidebar/CartSidebar';
import { LocationModal } from '@/modules/location/components/LocationModal/LocationModal';
import { Media } from '@lib/theme/components/Media';

import { CategoriesModal } from '../CategoriesModal';
import { Footer } from '../Footer';
import { JushAd } from '../JushAd/JushAd';
import { Layout } from '../Layout';
import { NavBar } from '../NavBar';
import { NavBarBoxProvider } from '../NavBarBoxProvider';

export const RichLayout = ({ children }: { children: ReactNode }) => (
  <NavBarBoxProvider>
    <Layout navbar={<NavBar />} footer={<Footer />}>
      {children}
      <Media clientOnly>
        <Portal>
          <CartProgressIndicator />
        </Portal>
        <JushAd />
        <CartSidebar />
        <LocationModal />
        <CategoriesModal />
      </Media>
    </Layout>
  </NavBarBoxProvider>
);
