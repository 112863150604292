import dynamic from 'next/dynamic';

import { mobileAppUrls } from '@/core/constants/appUrls';
import { breakpoints } from '@lib/theme/config/breakpoints';
import { useMedia } from '@lib/theme/hooks/useMedia';

const GooglePlayBadgeSvg = dynamic(
  async () => import('./google-play-badge.svg'),
  { ssr: false }
);

export const GooglePlayBadge = ({
  height,
  className,
}: {
  height: number;
  className?: string;
}) => {
  const { windowInnerWidth } = useMedia();

  return (
    <a
      href={
        windowInnerWidth && windowInnerWidth <= breakpoints.lg
          ? mobileAppUrls.appStoreDeepLink
          : mobileAppUrls.android
      }
      target="_blank"
      rel="noreferrer"
      aria-label="Google Play"
      className={className}
    >
      <GooglePlayBadgeSvg height={height} />
    </a>
  );
};
