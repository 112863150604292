import dynamic from 'next/dynamic';

import { selectCartProgressIndicatorState } from '@/store/selectors/cartProgressIndicatorSelectors';
import { useAppSelector } from '@/store/storeHooks';

const CartProgressIndicatorContent = dynamic(
  async () =>
    (
      await import(
        './components/CartProgressIndicatorContent/CartProgressIndicatorContent'
      )
    ).CartProgressIndicatorContent,
  { ssr: false }
);

export const CartProgressIndicator = () => {
  const { isOpen } = useAppSelector(selectCartProgressIndicatorState);

  return isOpen !== null && <CartProgressIndicatorContent />;
};
