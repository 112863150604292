import dynamic from 'next/dynamic';

import { mobileAppUrls } from '@/core/constants/appUrls';
import { breakpoints } from '@lib/theme/config/breakpoints';
import { useMedia } from '@lib/theme/hooks/useMedia';

const AppStoreBadgeSvg = dynamic(async () => import('./app-store-badge.svg'), {
  ssr: false,
});

export const AppStoreBadge = ({
  height,
  className,
}: {
  height: number;
  className?: string;
}) => {
  const { windowInnerWidth } = useMedia();

  return (
    <a
      href={
        windowInnerWidth && windowInnerWidth <= breakpoints.lg
          ? mobileAppUrls.appStoreDeepLink
          : mobileAppUrls.iphone
      }
      target="_blank"
      rel="noreferrer"
      aria-label="App Store"
      className={className}
    >
      <AppStoreBadgeSvg height={height} />
    </a>
  );
};
