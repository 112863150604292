import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const CategoriesModalInner = dynamic(
  async () => (await import('./CategoriesModalInner')).CategoriesModalInner,
  { ssr: false }
);

export const CategoriesModal = () => (
  <Suspense fallback={null}>
    <CategoriesModalInner />
  </Suspense>
);
