import dynamic from 'next/dynamic';

import { useAppsFlyer } from '@/core/hooks/useAppsFlyer';
import { useHideChat } from '@/core/hooks/useHideChat';
import { openLocationSchedulerModal } from '@/store/slices/locationScheduler';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { useLocationModal } from '../../hooks/useLocationModal';

const LocationModalInner = dynamic(
  async () => (await import('./LocationModalInner')).LocationModalInner,
  { ssr: false }
);
export const LocationModal = () => {
  const isLocationModalOpen = useAppSelector(
    (state) => state.locationScheduler.isOpen
  );
  const { closeLocationModal } = useLocationModal();
  useHideChat(isLocationModalOpen);

  const dispatch = useAppDispatch();
  const { queryParamValue, removeAppsFlyerQueryParam } = useAppsFlyer({
    appsFlyerQueryParam: 'address',
    enabled: !isLocationModalOpen,
    onSuccess: () => {
      if (queryParamValue === 'open') {
        dispatch(openLocationSchedulerModal('AddressPanel'));
      }
    },
  });

  const closeModal = async () => {
    await removeAppsFlyerQueryParam();
    closeLocationModal();
  };

  return isLocationModalOpen ? (
    <LocationModalInner closeModal={closeModal} />
  ) : null;
};
